<template>
    <div class="GoodsType">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="货物类型名称" prop="name">
                <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入货物类型名称" maxlength="50" :disabled="disabled" clearable/>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>
    import GoodsTypeCardHelper from "./GoodsTypeCardHelper.js";
    export default GoodsTypeCardHelper;
</script>

<style scoped>
    .GoodsType{
        width: 100%;
    }
</style>
